<route>
{
  "meta": {
    "auth": "statisticsDeal"
  }
}
</route>

<template>
  <div v-loading="loading" element-loading-text="正在查询，请稍候" element-loading-spinner="el-icon-loading">
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="14">
          <el-date-picker
            value-format="timestamp"
            v-model="searchValue"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button style="margin-left: 8px" type="success" @click="download()">导出</el-button>
        </el-col>

        <el-col :span="8">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getDeal()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="card">
      <div class="dealContainer">
        <div class="dealText">
          <div class="dealItem" v-for="(item, index) in dealText" :key="index">
            <bm-tween class="number" :value="item.number"></bm-tween>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
        <div class="funnelChart" ref="dealChart"></div>
      </div>
    </el-card>

    <el-card class="card">
      <div class="dealChart"><div class="chart" ref="saleTrendingChart"></div></div>
    </el-card>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  data() {
    return {
      dealChartInstance: null,
      chartInstance: null,
      data: {},
      searchValue: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      dealText: [
        { text: '浏览人数', value: 'viewMemberCount', number: 0 },
        { text: '下单人数', value: 'orderMemberCount', number: 0 },
        { text: '订单数', value: 'orderCount', number: 0 },
        { text: '下单件数', value: 'applySkuCount', number: 0 },
        { text: '核销件数', value: 'applySkuCheckCount', number: 0 },
        { text: '下单金额 (元)', value: 'orderPayMoney', number: 0 },
        { text: '退款金额 (元)', value: 'refundOrderMoney', number: 0 },
        { text: '付款人数', value: 'memberOfPayCount', number: 0 },
        { text: '付款订单数', value: 'orderOfPayCount', number: 0 },
        { text: '付款件数', value: 'skuOfPayCount', number: 0 },
        { text: '付款金额 (元)', value: 'totalActualPayOfOrder', number: 0 },
        { text: '客单价 (元)', value: 'customerPrice', number: 0 }
      ],
      dealChart: [],
      loading: false
    }
  },
  created() {
    this.getDeal()
  },
  watch: {
    dealChart() {
      this.initFunnelChart()
    },
    data() {
      this.initChart()
    }
  },
  methods: {
    download() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .get('boom-center-statistics-service/sysAdmin/exportExl/productSell', {
          params: {
            startTime: this.searchValue ? this.searchValue[0] : '',
            endTime: this.searchValue ? this.searchValue[1] : ''
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    initChart() {
      this.$nextTick(() => {
        this.chartInstance = echarts.init(this.$refs.saleTrendingChart)
        this.updateChartDate()
      })
    },
    initFunnelChart() {
      this.$nextTick(() => {
        this.dealChartInstance = echarts.init(this.$refs.dealChart)
        this.updateFunnelChartDate()
      })
    },
    updateChartDate() {
      this.chartInstance.setOption({
        color: this.$store.getters['echarts/colorList'],
        xAxis: {
          type: 'category',
          data: this.data.timeList
        },
        yAxis: [
          {
            name: '金额或人数或百分比',
            type: 'value'
          }
          // {
          //   name: '转化率',
          //   // nameLocation: 'start',
          //   // max: 100,
          //   type: 'value'
          //   // inverse: true
          // }
        ],
        legend: {
          data: ['付款金额', '退款金额', '付款人数', '付款件数', '下单转化率', '付款转化率', '成交转化率']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100
          }
        ],
        series: [
          {
            name: '付款金额',
            type: 'line',
            stack: '金额',
            data: this.data.payForMoneyList,
            smooth: true
          },
          {
            name: '退款金额',
            type: 'line',
            stack: '金额',
            data: this.data.refundMoneyList,
            smooth: true
          },
          {
            name: '付款人数',
            type: 'line',
            stack: '金额',
            data: this.data.payForMemberList,
            smooth: true
          },
          {
            name: '付款件数',
            type: 'line',
            stack: '金额',
            data: this.data.payforskuCountList,
            smooth: true
          },
          {
            name: '下单转化率',
            type: 'line',
            stack: '金额',
            // yAxisIndex: 1,
            data: this.data.orderPercentList,
            smooth: true
          },
          {
            name: '付款转化率',
            type: 'line',
            stack: '金额',
            // yAxisIndex: 1,
            data: this.data.payForPercentList,
            smooth: true
          },
          {
            name: '成交转化率',
            type: 'line',
            stack: '金额',
            // yAxisIndex: 1,
            data: this.data.dealedPercentList,
            smooth: true
          }
        ]
      })
    },
    updateFunnelChartDate() {
      this.dealChartInstance.setOption({
        color: this.$store.getters['echarts/colorList'],
        title: {
          text: '漏斗图',
          subtext: '转化率'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}%'
        },
        toolbox: {
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        legend: {
          data: ['浏览', '下单', '付款']
        },
        series: [
          {
            name: '漏斗图',
            type: 'funnel',
            left: '10%',
            top: 60,
            //x2: 80,
            bottom: 60,
            width: '80%',
            // height: {totalHeight} - y - y2,
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'inside'
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                fontSize: 20
              }
            },
            data: [
              // { value: this.dealChart[0], name: '浏览' },
              // { value: this.dealChart[1], name: '下单' },
              // { value: this.dealChart[2], name: '付款' }

              { value: 80, name: '浏览' },
              { value: 40, name: '下单' },
              { value: 20, name: '付款' }
            ]
          }
        ]
      })
    },
    getDeal() {
      const params = {
        params: {
          startTime: this.searchValue ? this.searchValue[0] : '',
          endTime: this.searchValue ? this.searchValue[1] : ''
        }
      }
      this.loading = true

      this.$api.statistics
        .statistics('getDeal', params)
        .then(res => {
          this.dealText.forEach(item => {
            item.number = res[item.value]
          })
          const order = res.orderMemberCount / res.viewMemberCount
          res
          const paly = res.memberOfPayCount / res.orderMemberCount
          const deal = res.orderMemberCount / res.viewMemberCount

          this.dealChart.push(order)
          this.dealChart.push(paly)
          this.dealChart.push(deal)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
      this.$api.statistics
        .statistics('getDealChart', params)
        .then(res => {
          this.data = res
        })
        .catch(err => {
          console.log(err)
          this.$message.error(err.msg || '操作失败')
        })
    },
    resetSearch() {
      this.searchValue = []
      this.getDeal()
    }
  }
}
</script>

<style scoped lang="less">
.card {
  margin: 20px;
}
.dealContainer {
  display: flex;

  margin-top: 10px;
  .dealText {
    width: 1150px;
    display: flex;
    flex-wrap: wrap;

    .dealItem {
      width: 350px;
      text-align: center;
      margin: 0 10px;
      margin-top: 50px;
      .text {
        // font-weight: bold;
        margin-top: 20px;
      }
      .number {
        color: red;
        font-size: 40px;
        margin-top: 10px;
      }
    }
  }
  .funnelChart {
    width: 450px;
    height: 400px;
  }
}
.chart {
  height: 500px;
  width: 100%;
}
</style>
